import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EtheringtonPitch from './EtheringtonPitch';
import GruttoPitch from './GruttoPitch';
import PropTypes from 'prop-types';
import './video-pitch.scss';

const Pitch = ({ match }) => (
  <section className="video-pitch">
    <Switch>
      <Route path={ `${match.url}/mark`} component={ EtheringtonPitch } />
      <Route path={ `${match.url}/berend` } component={ GruttoPitch } />
    </Switch>
  </section>
);

Pitch.propTypes = {
  match: PropTypes.object
};

export default Pitch;

/* eslint-disable max-len */
import React from 'react';

const EtheringtonPitch = () => (
  <div className="content">
    <h4 className="confidential">confidential</h4>
    <h1 className="name">Mark Etherington</h1>
    <video className="video" controls>
      <source
        type="application/x-mpegURL"
        src="https://media.goodbeefindex.org/pitch/etheringtons/hls/master.m3u8" />
      <source
        type="video/mp4"
        src="https://media.goodbeefindex.org/pitch/etheringtons/video.mp4"/>
    </video>
  </div>
);

export default EtheringtonPitch;
